.state-view {
  width: 100%;
  background-color: red;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.state-view li {
  align-items: center;
  justify-content: center;
}

.state-ok {
  background-color: green;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: inline-block;
  justify-content: center;
  box-sizing: border-box;
}

.state-bad {
  background-color: red;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: inline-block;
  justify-content: center;
  box-sizing: border-box;
}
