@media screen and (max-width: 500px) {
  .page-sidebar-container {
    pointer-events: none;
    position: fixed;
    display: flex;
    flex-direction: row;
    min-height: 100%;
    width: 60%;
    height: 100%;
    z-index: 1;
  }

  .page-sidebar {
    pointer-events: all;
    overflow: hidden;
    border-right: 1px;
    border-right-style: solid;
    border-right-color: black;
    border-top: 1px;
    border-top-style: solid;
    border-top-color: black;
    /* border-radius: 10px; */
    width: 0%;
    height: 100%;
    transition: width 1s;
  }

  .page-sidebar * {
    pointer-events: all;
  }
}

@media screen and (min-width: 501px) {
  .page-sidebar-container {
    min-height: 100vh;
    width: 15%;
    border-right: 1px;
    border-right-style: solid;
    border-right-color: black;
  }

  .page-sidebar {
    width: 100%;
    height: 100%;
  }
}
