@media screen and (min-width: 391px) {
  .sidebar-container {
    font-family: "Montserrat", sans-serif;
    height: 100%;
    color: black;
    background: rgb(63, 135, 193);
    background: linear-gradient(
      187deg,
      rgba(191, 215, 234, 1) 0%,
      rgba(191, 215, 224, 1) 80%,
      rgba(191, 215, 204, 1) 100%
    );
  }
}

@media screen and (max-width: 390px) {
  .sidebar-container {
    font-family: "Montserrat", sans-serif;
    height: 100%;
    color: black;
    background: rgb(63, 135, 193);
    background: linear-gradient(
      187deg,
      rgba(191, 215, 234, 0.9) 0%,
      rgba(191, 215, 224, 0.9) 80%,
      rgba(191, 215, 204, 0.9) 100%
    );

    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.7);
  }
}

.sidebar-container h1 {
  text-align: left;
  font-size: 20px;
  padding-top: 20px;
  padding-left: 5px;
}

.sidebar-container ul {
  text-align: left;
  margin: 0px 20px;
  list-style: none;
}

.sidebar-container li {
  margin-top: 10px;
}

.sidebar-container li a {
  color: black;
  text-decoration: none;
}

.sidebar-container li a:hover {
  color: red;
  transition: color 0.2ms;
}
