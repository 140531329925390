@media screen and (min-width: 501px) {
  .data-view-grid {
    min-height: 100vh;
    height: auto;
    width: 85%;
    background: linear-gradient(
      90deg,
      rgba(189, 189, 189, 1) 0%,
      rgba(225, 225, 233, 1) 65%,
      rgba(232, 250, 254, 1) 100%
    );
  }

  .data-view-grid-row {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .data-view-item {
    background-color: white;
    border-radius: 8px;
    box-shadow: 5px 5px 5px silver;
    width: 100%;
    height: auto;
    /* height: 250px; */

    margin-left: 20px;
    margin-right: 20px;
  }
}

@media screen and (max-width: 500px) {
  .data-view-grid {
    min-height: 100vh;
    height: auto;
    width: 100%;
    background: linear-gradient(
      90deg,
      rgba(189, 189, 189, 1) 0%,
      rgba(225, 225, 233, 1) 65%,
      rgba(232, 250, 254, 1) 100%
    );
  }

  .data-view-grid-row {
    display: flex;
    flex-direction: column;
  }

  .data-view-item {
    background-color: white;
    border-radius: 8px;
    box-shadow: 5px 5px 5px silver;
    width: 90%;
    height: auto;
    /* height: 250px; */
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 20px;
    margin-right: 20px;
  }
}

.data-view-item:hover {
  transition: 0.2s;
  box-shadow: 0 5px 15px rgba(192, 180, 180, 1);
}
