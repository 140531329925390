@media screen and (max-width: 500px) {
  .dropout-btn {
    display: block;
    pointer-events: all;
    position: relative;
    background-color: rgba(0, 0, 0, 0);
    border-width: 0px;
    margin-top: 15px;
    margin-left: 3px;
    width: 20px;
    height: 30px;
  }

  .dropout-btn svg {
    width: 100%;
    fill: black;
  }

  #out-svg {
    display: none;
  }

  .dropout-btn svg:active {
    fill: rgba(255, 0, 0, 0.5);
  }
}

@media screen and (min-width: 501px) {
  .dropout-btn {
    display: none;
  }
}
