.home {
  height: 100%;
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(
    90deg,
    rgba(191, 215, 234, 1) 0%,
    rgba(225, 225, 233, 1) 65%,
    rgba(232, 250, 254, 1) 100%
  );
}

.head-img {
  width: 100%;
}

.title-text {
  font-family: "Montserrat", sans-serif;
  /* font-size: 32px;
  font-weight: 700; */
}

.title-text-1 {
  font-family: "Montserrat", sans-serif;
  font-size: 60px;
  font-weight: 900;
}

.title-text-2 {
  text-align: right;
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: 200;
}

.title-text-3 {
  text-align: right;
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: 200;
}

@media screen and (min-width: 501px) {
  .title-text {
    position: absolute;
    top: 30%;
    left: 15%;
    transform: translate(-50%, -50%);
  }
}

@media screen and (max-width: 500px) {
  .title-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
