.piececount-view-container {
  font-family: "Montserrat", sans-serif;
  text-align: left;
  width: 100%;
  height: 250px;
  box-sizing: border-box;
  padding: 20px;
}

.piececount-view-container h1 {
  text-align: left;
  font-size: 18px;
  font-weight: 700;
}

.piececount-data-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.piececount {
  text-align: center;
  font-size: 28px;
  font-weight: 900;
}

.piececount-view-container ul {
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.piececount-view-container ul li {
  margin-top: 15px;
  margin-right: 10px;
}

.arrow-container img {
  width: 20px;
  height: 20px;
  background-color: red;
}
