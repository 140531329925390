@media screen and (min-width: 501px) {
  .settings-table {
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .date-selection {
    display: flex;
    flex-direction: row;
  }

  .date-selection li {
    margin-right: 2%;
  }

  .date-selection ul {
    display: flex;
    flex-direction: column;
    margin-right: 5%;
    text-align: left;
  }
}

@media screen and (max-width: 500px) {
  .settings-table {
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .date-selection {
    display: flex;
    flex-direction: column;
  }

  .date-selection li {
    margin-right: 2%;
    margin-top: 5px;
  }

  .date-selection ul {
    display: flex;
    flex-direction: column;
    margin-right: 5%;
    text-align: left;
  }
}

.settings-table li {
  width: 100%;
}

.settings-container {
  font-family: "Montserrat", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
  width: 100%;
  /* height: 250px; */
  box-sizing: border-box;
  padding: 20px;
}

.settings-container h1 {
  text-align: left;
  font-size: 18px;
  font-weight: 700;
}

.settings-container h2 {
  font-size: 18px;
  font-weight: 600;
}

.settings-container ul {
  /* display: flex;
  flex-direction: row; */
  list-style: none;
}

.machine-info-container {
  width: 100%;
  margin-top: 10px;
}

.machine-info-list-row {
  display: flex;
  flex-direction: row;
}

.machine-info-list-row li {
  margin-right: 1%;
}

.dataview-settings-container {
  margin-top: 10px;
}

.image-container {
  height: 220px;
  width: 220px;
  border-radius: 50%;
  border-color: black;
  border-style: solid;
  border-width: 1px;
  display: inline-block;
  justify-content: center;
  box-sizing: border-box;
  margin: 20px;
}

.version-overview-container {
  font-family: "Montserrat", sans-serif;
  box-sizing: border-box;
  margin: 20px;
  text-align: left;
}

.version-overview-container h2 {
  font-size: 18px;
  font-weight: 600;
}

.version-overview-container ul {
  list-style-type: none;
}
