.loading-container {
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  height: 100vh;
  background-color: rgba(191, 215, 234, 1);
}

.preload-animation {
  fill: none;
  /* stroke: #bd3838; */
  stroke: rgba(210, 43, 61, 1);
  stroke-width: 0;

  /* animation: revealPath 0.9s infinite linear; */
}

#loading {
  -webkit-transition: all 0.7s linear;
  transition: all 0.7s linear;
}

#loading {
  display: flex;

  align-items: center;

  justify-content: center;

  flex-direction: column;
  width: 100%;
  height: 100vh;
}

#loading svg .obj {
  /* opacity: 0; */
  animation-name: revealPath;
  animation-duration: 5s;
  animation-iteration-count: infinite;
}

#loading svg .obj.obj01 {
  animation-delay: 0.25s;
}

#loading svg .obj.obj02 {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

#loading svg .obj.obj03 {
  -webkit-animation-delay: 0.75s;
  animation-delay: 0.75s;
}

#loading svg .obj.obj04 {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

#loading svg .obj.obj05 {
  -webkit-animation-delay: 1.25s;
  animation-delay: 1.25s;
}

#loading svg .obj.obj06 {
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;
}

#loading svg .obj.obj07 {
  -webkit-animation-delay: 1.75s;
  animation-delay: 1.75s;
}

#loading svg .obj.obj08 {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

#loading svg .obj.obj09 {
  -webkit-animation-delay: 2.25s;
  animation-delay: 2.25s;
}

#loading svg .obj.obj10 {
  -webkit-animation-delay: 2.5s;
  animation-delay: 2.5s;
}

@keyframes revealPath {
  0% {
    stroke-width: 0;
  }
  5% {
    stroke-width: 3;
  }
  50% {
    stroke-width: 3;
  }
  55% {
    stroke-width: 0;
  }
  100% {
    stroke-width: 0;
  }
}
