@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;600;800;900&display=swap");

.input-container {
  font-family: "Montserrat", sans-serif;

  width: 100%;
  height: 100vh;

  background: rgb(189, 189, 189);
  background: linear-gradient(
    90deg,
    rgba(189, 189, 189, 1) 0%,
    rgba(225, 225, 233, 1) 65%,
    rgba(232, 250, 254, 1) 100%
  );
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login-form {
  min-width: 250px;
  min-height: 300px;
  width: 25%;
  height: 50%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-style: solid;
  border-radius: 10px;
  background-color: white;
}

.login-form h1 {
  margin-bottom: 20px;
}

.login-form label {
  margin-top: 10px;
  margin-bottom: 5px;

  font-weight: 600;
}

.login-form button {
  margin-top: 10px;
  width: 50px;
  height: 30px;
  border-radius: 10px;
  border-color: red;
  background-color: red;
}

.login-form button:hover {
  color: red;
  background-color: white;
  transition: color 0.2s;
  transition: background-color 0.2s;
}
