.overview-table-container {
  font-family: "Montserrat", sans-serif;
  text-align: left;
  width: 100%;
  height: fit-content;
  box-sizing: border-box;
  padding: 20px;
}

.overview-table-container h1 {
  text-align: left;
  font-size: 18px;
  font-weight: 700;
}

.overview-table-container h2 {
  text-align: left;
  font-size: 16px;
  font-weight: 700;
  border-bottom: solid;
  border-color: black;
  border-width: 1px;
}

.overview-table-list {
  display: flex;
  flex-direction: column;
  justify-content: top;
  height: 100%;
}

.overview-machine-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 10px;
  margin-right: 10px;
}

.overview-machine-row p {
  width: 25%;
  background-color: aliceblue;
}

@media screen and (min-width: 501px) {
  .overview-table-container h2 {
    width: 25%;
  }

  .overview-machine-row p {
    width: 25%;
    background-color: aliceblue;
  }

  .last-update {
    display: block;
  }
}

@media screen and (max-width: 500px) {
  .overview-table-container h2 {
    width: 33%;
  }

  .overview-machine-row p {
    width: 33%;
    background-color: aliceblue;
  }

  .last-update {
    width: 0px;
    display: none;
  }
}
