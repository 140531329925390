@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;600;800;900&display=swap");

* {
  margin: 0;
  padding: 0;
}

/* .content {
  width: 100%;
  height: 100%;
} */

@media screen and (min-width: 1501px) {
  .header {
    width: 100%;
    /* height: 50px; */
    background-color: white;
    display: flex;
    padding-left: 5%;
    padding-right: 5%;
    box-sizing: border-box;
    justify-content: space-between;
    font-family: "Montserrat", sans-serif;

    border-bottom: 1px;
    border-bottom-color: black;
    border-bottom-style: solid;

    /* position: fixed; */
  }

  .logo {
    width: 10%;
    height: 70px;
    /* background-color:  yellow; */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .nav {
    width: 30%;
    height: 70px;
    /* background-color: yellow; */
  }
  .drop-btn {
    display: none;
  }

  .nav_ul {
    font-size: 24px;
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style-type: none;
  }
}

@media screen and (max-width: 1500px) {
  .header {
    width: 100%;
    /* height: 50px; */
    display: flex;
    padding-left: 5%;
    padding-right: 1%;
    box-sizing: border-box;
    justify-content: space-between;
    font-family: "Montserrat", sans-serif;

    border-bottom: 1px;
    border-bottom-color: black;
    border-bottom-style: solid;

    /* position: fixed; */
  }
  .logo {
    height: 50px;
    /* background-color:  yellow; */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .logo img {
    height: 30px;
    /* background-color:  yellow; */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .nav {
    top: 5px;
    width: 60%;
    height: 50px;
    /* background-color: aqua; */
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: end;
  }

  .drop-btn {
    border-style: none;
    background-color: white;
    height: 80%;
    width: 30%;
    max-width: 80px;
    display: inline-block;
    border-radius: 20px;
  }

  .drop-btn img {
    width: 70%;
    height: 90%;
    z-index: 1;
    border-radius: 10px;
  }

  .nav_ul {
    display: block;
    font-size: 24px;
    width: 100%;
    list-style-type: none;
    min-width: 160px;
    background-color: rgba(255, 255, 255, 0.7);
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.7);
    border-radius: 20px;
    /* z-index: 1; */
  }

  .drop-btn img:active {
    background-color: rgba(155, 216, 252, 0.2);
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  }
}

.nav_ul li a {
  color: black;
  text-decoration: none;
}

.nav_ul li a:hover {
  color: red;
  transition: color 0.2ms;
}

.login {
  background-color: red;
  padding: 5px 2%;
  border: 1px;
  border-radius: 17px;
  border-color: red;
  border-style: solid;
}

.login:hover {
  background-color: white;
  transition: background-color 0.2s;
}
.head_img_container {
  overflow: hidden;
  width: 100%;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}

.banner-container {
  display: flex;
  background-color: white;
  width: 100%;
  height: 100vh;
}

.title_text {
  position: absolute;
  top: 30%;
  left: 10%;
  font-family: "Montserrat", sans-serif;
  display: flex;
  flex-direction: column;
}

.title_text_1 {
  font-weight: 900;
  font-size: 70px;
}

.title_text_2 {
  text-align: right;
  font-weight: 400;
  font-size: 20px;
}

.title_text_3 {
  margin-top: 30px;
  font-weight: 500;
  font-size: 30px;
}

@media screen and (min-width: 1301px) {
  .footer-container {
    /* position: relative; */
    /* bottom: 0px; */
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    /* padding-left: 5%;
    padding-right: 5%; */
    border-top: 1px;
    border-top-color: black;
    border-top-style: solid;
  }

  .footer-logo {
    padding-left: 1%;
  }

  .footer-link-container {
    width: 30%;
    padding-right: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .footer-links {
    font-size: 24px;
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style-type: none;
    text-decoration: none;
  }
}

@media screen and (max-width: 1300px) {
  .footer-container {
    width: 100%;
    height: 45px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    border-top: 1px;
    border-top-color: black;
    border-top-style: solid;
  }

  .footer-logo {
    display: none;
    padding-left: 1%;
  }

  .footer-logo img {
    height: 30px;
  }

  .footer-link-container {
    width: 100%;
    padding-right: 5%;
    padding-left: 5%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .footer-links {
    font-size: 24px;
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style-type: none;
    text-decoration: none;
  }
}

.footer-links li a {
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  color: black;
  text-decoration: none;
}

.footer-links li a:hover {
  color: red;
  transition: color 0.2ms;
}
