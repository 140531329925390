.App {
  width: 100%;
  overflow: hidden;
}

.app-content-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  width: 100%;
  /* overflow: hidden; */
}

.page {
  flex: 1;
  box-sizing: border-box;
}

.footer-container {
  margin-top: auto;
}
