.line-chart-container {
  font-family: "Montserrat", sans-serif;

  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  width: 90%;
  margin: 20px;
}

.line-chart-container h2 {
  text-align: left;
  font-size: 18px;
}
